import { createUseStyles } from 'react-jss';


const useStyles = createUseStyles({
  wrapper: {
    paddingBottom: '2em',
    paddingTop: '2em',
    textAlign: 'center',
  },

  '@keyframes rolling': {
    to: {
      transform: 'rotate(1turn)',
    }
  },

  '@keyframes fadein': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },

  img: {
    animation: '$rolling 2s ease-in-out infinite, $fadein .5s ease-in',
  },
});

export function Loader() {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <img className={styles.img} src="/logo192.png" alt="loading" />
    </div>
  );
}
