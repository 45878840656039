export class UTCDate {
  /**
   * @param {Date} date 
   */
  constructor(date) {
    this._date = new Date(date);
    this._date.setUTCHours(0, 0, 0, 0);
  }

  /**
   * @returns {UTCDate}
   */
  static today() {
    const date = new Date();

    return new UTCDate(date);
  }

  /**
   * @returns {Date}
   */
  toDate() {
    return this._date;
  }

  /**
   * @returns {UTCDate}
   */
  addMilliseconds(milliseconds) {
    this._date = new Date(this._date.valueOf() + milliseconds);

    return this;
  }

  /**
   * @returns {UTCDate}
   */
  addDays(days) {
    return this.addMilliseconds(days * 86400000);
  }

  /**
   * @returns {String}
   */
  toISODateString() {
    return this._date.toISOString().slice(0, 10);
  };
}