import {
  createSlice,
  configureStore,
  createSerializableStateInvariantMiddleware,
} from '@reduxjs/toolkit';
import I from 'immutable';


export const serverData = createSlice({
  name: 'serverData',
  initialState: I.Map({
    fetchingServerData: true,
    courses: I.List(),
  }),
  reducers: {
    setFetchingServerData(state, { payload }) {
      return state.set('fetchingServerData', payload);
    },
    setCourses(state, { payload }) {
      return state.set('courses', I.fromJS(payload));
    },
  },
});
export const errorData = createSlice({
  name: 'errorData',
  initialState: I.Map({
    unexpectedError: false,
    tooManyCalls: false,
  }),
  reducers: {
    setUnexpectedError(state, { payload }) {
      return state.set('unexpectedError', payload)
    },
    setTooManyCalls(state, { payload }) {
      return state.set('tooManyCalls', payload)
    },
  },
});

export const store = configureStore({
  reducer: {
    serverData: serverData.reducer,
    errorData: errorData.reducer,
  },
  middleware: [
    createSerializableStateInvariantMiddleware({ ignoredPaths: [new RegExp('.+')] }),
  ],
});
