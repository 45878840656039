import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './locales/en.json';
import es from './locales/es.json';


export const LANGUAGES = [
  ['en', '🇬🇧'],
  ['es', '🇪🇸'],
  // ['fr', '🇫🇷'],
  // ['ua', '🇺🇦'],

  // ['ru', '💩'],  never to be activated
]

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'custom',
  lookup() {
    const abbreviations = LANGUAGES.map(([abbr, _]) => abbr)
    const language = navigator.languages.find(lng => abbreviations.includes(lng))

    return language || 'en'
  },
})


i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: { en, es },
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'custom'],
      lookupLocalStorage: 'i18nextLng',
    }
  })
