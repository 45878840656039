import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Columns,
  Container,
  Form,
  Footer,
  Heading,
  Hero,
  Image,
  Notification,
  Progress,
  Tile, Message,
} from 'react-bulma-components';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { useForm } from "react-hook-form";
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
// noinspection JSUnresolvedReference
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

import { reactHookFormRegisterAdapter } from './adapters';
import { fetchCoursesDetails, fetchSlots, submitBookingForm } from './api';
import { Loader, WavesBackground } from './components';
import { UTCDate } from './date';
import { LANGUAGES } from './i18n';
import { errorData, serverData } from './store';


const CAPTCHA_SITE_KEY = '6LefN2olAAAAAD2JdIDDYdsp5-Zeaok8O0rGEghc'


function handleAPIResult(dispatch, result) {
  if (result.ok) return result.payload

  if (result.status === 429) {
    dispatch(errorData.actions.setTooManyCalls(true))
  } else {
    dispatch(errorData.actions.setUnexpectedError(true))
  }

  if (process.env?.NODE_ENV === 'development') console.dir(result)
  const error = Error()
  error.result = result
  throw error
}

export function App() {
  const dispatch = useDispatch()
  // Without this one the data gathering is fired twice.
  const initialDataGatheringStarted = useRef(false)

  useEffect(() => {
    if (!initialDataGatheringStarted.current) (
      async () => {
        initialDataGatheringStarted.current = true

        try {
          const courses = handleAPIResult(dispatch, await fetchCoursesDetails())
          dispatch(serverData.actions.setCourses(courses))
          dispatch(serverData.actions.setFetchingServerData(false))
        } catch {}

        loadReCaptcha(CAPTCHA_SITE_KEY)
      }
    )()
  }, [dispatch]);

  return (
    <div className="is-flex is-flex-direction-column" style={{ minHeight: '100vh' }}>
      <div className="is-flex-grow-1">
        <Header />
        <Main />
        <WavesBackground />
      </div>
      <div>
        <Footer>
          Rights reserved @ Freediving Nicaragua
          <br />
          Made by <a href="https://www.linkedin.com/in/vasyl-bratushka-55426265/" target="_blank" rel="noreferrer">Vasyl Bratushka</a> 🇺🇦
        </Footer>
      </div>
    </div>
  );
}

const useHeaderStyles = createUseStyles({
  positioner: {
    position: 'relative',
  },
  logo: {
    filter: 'invert(100%)',
  },
})

function Header() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const styles = useHeaderStyles()

  return (
    <Hero className={styles.positioner} size="small">
      <Hero.Body>
        <Container fullhd className="is-flex is-justify-content-space-between is-align-items-center">
          <div className="is-flex is-align-items-center">
            <Image className={styles.logo + ' is-hidden-mobile ml-4'} src="/logo180.png" size={64} />
            <Heading className="has-text-white">{t('pageHeader')}</Heading>
          </div>
          <Form.Select
            backgroundColor="info-light"
            value={i18n.language}
            onChange={async ({ target: { value } }) => {
              await i18n.changeLanguage(value)

              dispatch(serverData.actions.setFetchingServerData(true))
              try {
                const courses = handleAPIResult(dispatch, await fetchCoursesDetails())
                dispatch(serverData.actions.setCourses(courses))
                dispatch(serverData.actions.setFetchingServerData(false))
              } catch {}
            }}
          >
            {LANGUAGES.map(([abbr, emoji]) => (
              <option key={abbr} value={abbr}>{emoji}</option>
            ))}
          </Form.Select>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

function Main() {
  const tooManyCalls = useSelector(state => state.errorData.get('tooManyCalls'))
  const unexpectedError = useSelector(state => state.errorData.get('unexpectedError'))
  const { t } = useTranslation()

  return (
    <Container p={5}>
      <Columns centered>
        <Columns.Column
          tablet={{ size: 8 }}
          desktop={{ size: 7 }}
          fullhd={{ size: 6 }}
        >
          <Box className="has-background-info-light p-3">
            {!tooManyCalls && !unexpectedError ? (
              <BookingForm />
            ) : (
              <Message color="danger" className="m-3">
                <Message.Header>{tooManyCalls ? t('tooManyCalls') : t('unexpectedError')}</Message.Header>
                <Message.Body>{t('comeBackLater')}</Message.Body>
              </Message>
            )}
          </Box>
        </Columns.Column>
      </Columns>
    </Container>
  )
}

const useBookingFormStyles = createUseStyles({
  calendarDay: {
    border: 'none !important',
    borderRadius: '3px !important',
    userSelect: 'none',
  },
})

function BookingForm() {
  const styles = useBookingFormStyles()
  const [calendarIsOpen, setCalendarIsOpen] = useState(false)
  const [formState, setFormState] = useState('form')
  const [slots, setSlots] = useState(null)
  const { t } = useTranslation()

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    register,
    formState: {errors},
    setError,
  } = useForm({
    defaultValues: {
      course: '',
      people: '',
    }
  })

  const dispatch = useDispatch()
  const courses = useSelector(state => state.serverData.get('courses'))
  const loading = useSelector(state => state.serverData.get('fetchingServerData'))

  const [captcha, setCaptcha] = useState(null);

  if (loading || formState === 'submitting') return (
    <Loader />
  );

  if (formState === 'submitted') return (
    <ThankYou />
  )

  const values = getValues();

  const courseProps = reactHookFormRegisterAdapter(register('course'), {
    onChange: async ({ target: { value } }) => {
      setSlots(null)
      setValue('course', value)
      setValue('people', '')
      setValue('start', undefined)

      try {
        const slots = handleAPIResult(dispatch, await fetchSlots(watch('course')))
        setSlots(slots)
      } catch {}
    }
  });
  const peopleProps = reactHookFormRegisterAdapter(register('people'), {
    onChange: ({ target: { value } }) => {
      setValue('people', value)
      setValue('start', undefined)
      setCalendarIsOpen(true)

    }
  });

  const onSubmit = async data => {
    setFormState('submitting');
    const error = await submitBookingForm(data, captcha)

    if (error) {
      setError('root', {message: error})
      setFormState('form')
    } else {
      setFormState('submitted')
    }
  };

  const [
    watchCourse,
    watchPeople,
    watchStart,
    watchEmail,
    watchTermsAndConditions,
  ] = watch(['course', 'people', 'start', 'email', 'termsAndConditions']);

  const includeDates = slots !== null && watchPeople && Object.entries(slots).map(([d, {available}]) => {
    if (!available) return null
    if (values.people > available) return null

    const localDateNow = new Date()
    const utcDate = new UTCDate(new Date(d))

    return utcDate
      .addMilliseconds(60000 * localDateNow.getTimezoneOffset())
      .toDate()
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {errors.root && (
        <Notification color="danger" light>
          <p>{t(errors.root.message)}</p>
        </Notification>
      )}

      <Form.Field>
        <Form.Label>{t('courseLabel')}</Form.Label>
        <Form.Control>
          <Form.Select
            color="info"
            required
            {...courseProps}
          >
            <option disabled hidden />
            {courses.map(course => {
              const value = course.get('name')
              const price = course.get('price')

              return (
                <option
                  key={value}
                  value={value}
                >{value} (${price})</option>
              );
            })}
          </Form.Select>
        </Form.Control>
        <Form.Help color="link">
          {t('courseHelp')}
        </Form.Help>
      </Form.Field>

      {watchCourse && (
        <Form.Field>
          <Form.Label>{t('peopleLabel')}</Form.Label>
          <Form.Control>
            <Form.Select
              color="info"
              required
              {...peopleProps}
            >
              <option disabled hidden />
              {Array(Math.min(
                6,
                courses.find(c => c.get('name') === watchCourse).get('total_slots')),
              ).fill(null).map((_, i) => {
                const price = courses.find(c => c.get('name') === watchCourse).get('price')

                return (
                  <option
                    key={i}
                    value={i + 1}
                  >{i + 1} ({t('totalPrice')}: ${price * (i + 1)})</option>
                )
              })}
            </Form.Select>
          </Form.Control>
          <Form.Help color="link">
            {t('peopleHelp')}
          </Form.Help>
        </Form.Field>
      )
      }

      {watchPeople && slots === null && (<Loader />)}

      {watchPeople && slots !== null &&(
        <Form.Field>
          <Form.Label>{t('startLabel')}</Form.Label>
          <Form.Control>
            {
              calendarIsOpen ? (
                <DatePicker
                  disabledKeyboardNavigation
                  onChange={value => {
                    setValue('start', value);
                    setCalendarIsOpen(false);
                  }}
                  calendarContainer={BookingCalendarContainer}
                  dayClassName={date => {
                    const key = new UTCDate(date).toISODateString();
                    if (!slots[key]) return;

                    const {available, booked} = slots[key];

                    if (values.people > available) return `has-background-danger has-text-white ${styles.calendarDay}`
                    if (booked) return `has-background-success-dark has-text-white ${styles.calendarDay}`
                    return styles.calendarDay
                  }}
                  inline
                  includeDates={includeDates}
                />
              ) : (
                <Button
                  color="info"
                  renderAs="span"
                  outlined
                  onClick={() => {
                    setValue('start', undefined);
                    setCalendarIsOpen(true);
                  }}
                >{t('startChangeButton')}</Button>
              )
            }
          </Form.Control>
          <Form.Help color="link">
            {t('startHelp')}
            {calendarIsOpen && <>
              <br />
              <span
                className={`react-datepicker react-datepicker__day has-background-white ${styles.calendarDay}`}
              >11</span> - this day is free for any courses of your choosing
              <br />
              <span
                className={`react-datepicker react-datepicker__day has-background-success-dark has-text-white ${styles.calendarDay}`}
              >12</span> - there's a course on this day you can join
              <br />
              <span
                className={`react-datepicker react-datepicker__day has-background-danger has-text-white ${styles.calendarDay}`}
              >13</span> - this day is busy
            </>}
          </Form.Help>
        </Form.Field>
      )}

      {watchStart && (
        <>
          <Itinerary
            itinerary={courses.find(c => c.get('name') === values.course).get('itinerary')}
            start={values.start}
          />

          <Form.Field>
            <Form.Label>{t('emailLabel')}</Form.Label>
            <Form.Control>
              <Form.Input
                required
                type="email"
                {...reactHookFormRegisterAdapter(register('email'))}
              />
            </Form.Control>
            <Form.Help color="primary">
              {t('emailHelp')}
            </Form.Help>
          </Form.Field>

          <Form.Field>
            <Form.Label>{t('messageLabel')}</Form.Label>
            <Form.Control>
              <Form.Textarea
                {...reactHookFormRegisterAdapter(register('message'))}
              />
            </Form.Control>
            <Form.Help color="primary">
              {t('messageHelp')}
            </Form.Help>
          </Form.Field>
        </>
      )}

      {watchStart && watchEmail && (
        <Form.Field>
          <Form.Control>
            <Form.Checkbox
              {...reactHookFormRegisterAdapter(register('termsAndConditions'))}
            >
              {t('termsAndConditionsLabel')} <a
                href="/freediving-nicaragua-TaC-2023apr29.pdf"
                target="_blank"
                rel="noreferrer"
                download
              >{t('termsAndConditionsLink')}</a>
            </Form.Checkbox>
          </Form.Control>
        </Form.Field>
      )}

      {watchStart && watchEmail && watchTermsAndConditions && (
        <ReCaptcha
          sitekey={CAPTCHA_SITE_KEY}
          action="book_course"
          verifyCallback={setCaptcha}
        />
      )}

      {watchStart && watchEmail && watchTermsAndConditions && captcha && (
        <Form.Field mt={3}>
          <Form.Control>
            <Button
              color="info"
              fullwidth
            >{t('submitButton')}</Button>
          </Form.Control>
        </Form.Field>
      )}

      <Progress
        color="success"
        value={(!!watchCourse + !!watchPeople + !!watchStart + !!watchEmail + !!watchTermsAndConditions) / 5}
      />
    </form >
  );
}

function BookingCalendarContainer({ className, children, ..._ }) {
  return (
    <div className="has-text-centered-mobile">
      <CalendarContainer className={className}>
        {children}
      </CalendarContainer>
    </div>
  );
}

function Itinerary({ itinerary, start }) {
  const [active, setActive] = useState(0)
  const { t } = useTranslation()

  return (
    <article className="panel is-link">
      <p className="panel-heading">
        {t('itineraryHeader')}
      </p>

      <p className="panel-tabs">
        {/* @TODO: this needs scroll when too many items */}
        {itinerary.map((el, i) => {
          const day = new Date();
          day.setDate(start.getDate() + i);

          return (
            <a
              key={i}
              href="/"
              className={i === active ? 'is-active' : null}
              onClick={event => event.preventDefault() || setActive(i)}
            >{day.toLocaleDateString()}</a>
          );
        })}
      </p>

      {itinerary.get(active).map((el, i) => (
        <div key={i} className="panel-block">
          <p className="control">
            {el}
          </p>
        </div>
      ))}
    </article>
  );
}

function ThankYou() {
  const { t } = useTranslation();

  return (
    <div>
      <Hero color="link" size="medium">
        <Hero.Body>
          <Heading>{t('welcomeTitle')}</Heading>
          <Heading subtitle>{t('welcomeSubtitle')}</Heading>
        </Hero.Body>
      </Hero>

      <p className="is-size-4 has-text-centered my-6 has-text-dark">{t('subscribe')}</p>

      <Tile kind="ancestor" vertical>
        <Tile>
          <Tile kind="parent" vertical>
            <Tile kind="child" renderAs={Notification} color="info">
              <Heading>Facebook</Heading>
              <Heading subtitle>
                <a href="https://www.facebook.com/FreedivingNicaragua" target="_blank" rel="noreferrer">Freediving Nicaragua</a>
              </Heading>
            </Tile>
            <Tile kind="child" renderAs={Notification} color="warning">
              <Heading>Scuba Schools International</Heading>
              <Heading subtitle>
                <a
                  href="https://www.divessi.com/blog/freediving"
                >SSI freediving blog</a>
              </Heading>
            </Tile>
          </Tile>
          <Tile kind="parent">
            <Tile kind="child" renderAs={Notification} color="danger">
              <Heading>Instagram</Heading>
              <Heading subtitle>
                @<a href="https://www.instagram.com/freedivingnicaragua/" target="_blank" rel="noreferrer">freedivingnicaragua</a>
              </Heading>
              <Image src="/insta.jpg" />
            </Tile>
          </Tile>
        </Tile>
        <Tile kind="parent">
          <Tile kind="child" renderAs={Notification} color="success">
            <a href="https://freediving-nicaragua.com/" target="_blank" rel="noreferrer">
              <Image src="/our-site.jpeg" />
            </a>
          </Tile>
        </Tile>
      </Tile>
    </div>
  );
}
