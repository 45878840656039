import { createUseStyles } from 'react-jss';


const useWavesBackgroundStyles = createUseStyles({
  positioner: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  container: {
    position: 'relative',
    textAlign: 'center',
    background: 'linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%)',
    color: 'white',
  },
  space: {
    width: '100%',
    height: '65vh',
  },
  waves: {
    position: 'relative',
    width: '100%',
    height: '15vh',
    marginBottom: '-7px',
    minHeight: '100px',
    maxHeight: '150px',
  },
  parallax: {
    '&>use': {
      animation: '$move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite',
    },
    '&>use:nth-child(1)': {
      animationDelay: '-2s',
      animationDuration: '7s',
    },
    '&>use:nth-child(2)': {
      animationDelay: '-3s',
      animationDuration: '10s',
    },
    '&>use:nth-child(3)': {
      animationDelay: '-4s',
      animationDuration: '13s',
    },
    '&>use:nth-child(4)': {
      animationDelay: '-5s',
      animationDuration: '20s',
    },
  },
  '@keyframes move-forever': {
    from: {
      transform: 'translate3d(-90px, 0, 0)',
    },
    to: {
      transform: 'translate3d(85px, 0, 0)',
    },
  }
});

export function WavesBackground() {
  const styles = useWavesBackgroundStyles();

  return (
    <div className={styles.positioner}>
      <p hidden>Waves animation by Goodkatz</p>
      <div className={styles.container}>
        <div className={styles.space}></div>
        <div>
          <svg className={styles.waves} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className={styles.parallax}>
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}