import { UTCDate } from './date';


const API = {
  development: 'http://localhost:81',
  production: 'https://freediving-nicaragua-calendar.fly.dev',
}[process.env.NODE_ENV]

const GET = () => ({
  headers: { 'Accept-Language': localStorage.getItem('i18nextLng') },
})
const POST = payload => ({
  method: 'POST',
  headers: { 'Content-Type': 'application/json', 'Accept-Language': localStorage.getItem('i18nextLng') },
  body: JSON.stringify(payload),
})

function url(path) {
  return `${API}/${path}`
}

export function Result(status, payload) {
  this.ok = status < 400
  this.status = status
  this.payload = payload
}

async function resultFromResponse(response, adaptor = async (r) => await r.json()) {
  return new Result(response.status, await adaptor(response))
}

/**
 * @returns {Promise<Result>}
 */
export async function fetchCoursesDetails() {
  const response = await fetch(url('courses-details/'), GET())

  return await resultFromResponse(response)
}

export async function fetchSlots(course) {
  const target = new URL(`${url('slots/')}?course=${course}`)
  const response = await fetch(target, GET())

  return await resultFromResponse(response)
}

export async function submitBookingForm(data, captcha) {
  const payload = {
    booking: {
      course_name: data.course,
      people: parseInt(data.people),
      email: data.email,
      start: new UTCDate(data.start).toISODateString(),
      message: data.message,
    },
    captcha,
  }

  let response
  try  {
    response = await fetch(url('booking/'), POST(payload))
  } catch {
    return 'somethingWrongTryAgain'
  }


  if (response.status !== 201) {
    const json = await response.json()

    return json['detail']
  }
}
